import { useState } from "react"
import { useNavigate } from "react-router-dom"
import logo from '../assets/logo.png';
import CnpjInput from '../components/Cnpj';

import { useUsuario } from '../context/usuario';

import api from '../services/api';

export default function Home() {
    const { login } = useUsuario();
    const navigate = useNavigate();

    const [username, setUsername] = useState('')
    const [pass, setPass] = useState('')
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    async function handleSubscribe(event) {
        event?.preventDefault()
        setLoading(true);
        if (!username || !pass) {
            setError('CNPJ e Senha obrigatórios');
            setLoading(false);
            return;
          }
        if (await login(getValueWithoutMask(username), pass)) {
            setError('');
            navigate('/event');
        }else{
            setError('Credenciais inválidas');
        }
        setLoading(false);
        

    }

    const determineMask = (value) => {
        // Remove caracteres não numéricos
        const numericValue = value.replace(/\D/g, '');
        // Usa máscara de CNPJ se tiver mais de 11 dígitos, caso contrário usa máscara de CPF
        return numericValue.length > 11 ? true : false;
      };

    const getValueWithoutMask = (cnpj) => {
        return cnpj.replace(/\D/g, "");
      };

    return (
        <div className="min-h-screen bg-blur bg-cover bg-no-repeat flex items-center justify-center">
            <div className="flex items-center w-full max-w-[1100px] justify-between mx-auto lg:flex-row md:flex-col sm: flex-col">
                <div className="max-w-[640px] flex flex-col">
                    <img src={logo} className="max-w-[250px]" />
                    <h1 className="text-[40px] mt-10 leading-tight hidden md:hidden lg:block">
                        Bem-vindo ao <strong className="text-blue-500">Portal do Locador</strong>
                    </h1>
                    <p className="mt-4 text-gray-200 leading-relaxed">
                        Onde o SL Online alavanca seu negócio
                    </p>
                </div>

                <div className="p-8 bg-gray-700 border border-gray-500 min-w-[350px] rounded">
                    <strong className="text-2xl mb-3 block">Acesso ao Portal</strong>
                    <p className="text-sm mb-3 text-red-300">{error}</p>
                    <form onSubmit={handleSubscribe} className="flex flex-col gap-2 w-full">
                        <CnpjInput
                            className="bg-gray-900 rounded px-5 h-14"
                            isCnpj={determineMask(username)}
                            onChange={event => setUsername(event.target.value)}
                        />
                        <input
                            className="bg-gray-900 rounded px-5 h-14"
                            type="password"
                            placeholder="Senha"
                            onChange={event => setPass(event.target.value)}
                        />

                        <button
                            type="submit"
                            disabled={loading}
                            className="mt-4 bg-green-500 uppercase py-4 font-bold text-sm rounded hover:bg-green-700 transition-colors disabled:opacity-50"
                        >
                            {loading? 'Carregando...': 'Entrar'}
                        </button>
                    </form>
                </div>
            </div>

            {
                //<img src="/src/assets/mockup2.png" alt="code" className="mt-20 mb-10" width={720} height={500}/>
            }
        </div>
    )
}