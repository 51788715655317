import React from 'react';

import {
  Routes,
  Route,
} from 'react-router-dom';

import Home from '../pages/Home.js';
import Event from '../pages/Event.js';

export default function Router() {
  return (
      <Routes>
        <Route path="/" exact element={<Home/>} />
        <Route path="/event" element={<Event/>} isPrivate/>
        <Route path="/event/lesson/:slug" element={<Event/>} isPrivate/>
      </Routes>
  );
}
