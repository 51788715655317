import React from 'react';
import MaskedInput from 'react-text-mask';

const CNPJMask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
const CPFMask = [/\d/,/\d/,/\d/,'.',/\d/,/\d/,/\d/,'.',/\d/,/\d/,/\d/,'-',/\d/,/\d/,/\d/];
const CNPJInput = ({ isCnpj = true, ...rest}) => (
  <MaskedInput
    mask={isCnpj ? CNPJMask : CPFMask}
    placeholderChar={'\u2000'}
    placeholder="CPF ou CNPJ"
    guide={false}
    placeholderChar={'\u2000'}
    showMask
    {...rest}
  />
);

export default CNPJInput;
