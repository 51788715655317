import { CheckCircle, Lock } from 'phosphor-react'
import { Link, useParams } from 'react-router-dom';

import { useUsuario } from '../context/usuario';

export function Lesson(props) {

    const { selectLesson, lesson } = useUsuario();

    const isActiveLesson = true;

    return (
        <a href='#' onClick={() => { selectLesson(props.slug) }}>
            <div className={`rounded border border-gray-500 p-4 mt-2 group-hover:border-blue-500 ${lesson.id == props.slug ? 'bg-blue-200' : 'bg-blue-500'}  hover:bg-blue-700 transition-colors`}>
                <header className="flex items-center justify-between">

                    <span className={`text-sm text-blue-500 font-medium flex items-center gap-2 ${isActiveLesson ? 'text-white' : ''}`}>
                        <CheckCircle size={20} />
                        Conteúdo Liberado
                    </span>
                </header>

                <strong className={`mt-5 block ${isActiveLesson ? 'text-white' : 'text-gray-200'}`}>
                    {props.title}
                </strong>
            </div>
        </a>
    )
}