import React, { useEffect } from 'react';
import { Header } from "../components/Header";
import { Sidebar } from "../components/Sidebar";
import { Video } from "../components/Video";

import { useUsuario } from '../context/usuario';



export default function Event() {
  const { lesson, selectLessonVal, getLesson } = useUsuario();
  useEffect(()=>{
    getLesson(selectLessonVal);
  }, [selectLessonVal])
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <Header />
      <main className="flex lg:flex-row md:flex-col flex-col flex-1">
        <Video lesson={lesson} />
        <Sidebar />
      </main>
    </div>
  );

}