import React, { createContext, useState, useEffect, useContext } from 'react';
import api from '../services/api';
import { useNavigate, } from 'react-router-dom';

import { DefaultUi, Player, Youtube, } from "@vime/react";


const UserContext = createContext({});

export const UsuarioProvider = ({ children }) => {
  const navigate = useNavigate();
  const [usuario, setUsuario] = useState(null);
  const [loading, setLoading] = useState(false);
  const [lesson, setLesson] = useState({});
  const [sidebar, setSidebar] = useState([]);
  const [selectLessonVal, setSelectLessonVal] = useState(null);

  useEffect(() => {
    recoveryData();
  }, [])

  const recoveryData = () => {
    const storaged = localStorage.getItem('slportal:usuario');

    let user;

    if (storaged) {
      user = JSON.parse(storaged);
      console.log(user.token);
      api.defaults.headers.Authorization = `Bearer ${user.token}`;
      setUsuario({ ...user });
    }
    console.log(user?.token);
    if (!user?.token) {
      navigate('/');
    } else {
      navigate('/event');
    }

  }

  const login = async (username, password) => {
    try {
      const { data } = await api.post('/session', { username: username, password: password });
      setUsuario(data);

      localStorage.setItem('slportal:usuario', JSON.stringify(data));
      api.defaults.headers.Authorization = `Bearer ${data.token}`;
      return true;
    } catch (error) {
      return false;
    }

  }

  const logout = () => {
    api.defaults.headers.Authorization = ``;
    localStorage.removeItem('slportal:usuario');
    navigate('/');
  }

  const getLesson = async (id) => {
    
    setLoading(true);
    try {
      const { data } = await api.get(`/lesson/${id}`);
      setLesson({ ...data, player: <Player controls><Youtube cookies videoId={data?.external_id} /><DefaultUi /></Player> });
      setLoading(false);
      return data
    } catch (error) {
      setLoading(false);
      return false;
    }

  }

  const selectLesson = async (id) => {

      setSelectLessonVal(id);
      return id;
  }

  const getSidebar = async () => {
    recoveryData();
    try {
      const { data } = await api.get('/sidebar');
      setSidebar(data);
      return data;
    } catch (error) {
      return false;
    }

  }

  return (
    <UserContext.Provider value={{
      user: usuario,
      lesson,
      sidebar,
      selectLessonVal,
      loading,
      login,
      logout,
      getLesson,
      getSidebar,
      selectLesson
    }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUsuario() {
  const context = useContext(UserContext);

  return context;
}