import React from "react";

import snooker from '../assets/snooker.png';
import logo from '../assets/logo.png';

const WelcomeBanner = () => {
  return (
    <div className="lg:flex lg:flex-1 lg:flex-col lg:justify-center lg:items-center lg:h-screen bg-gray-800 ">
      <img
        src={snooker}
        alt="Welcome"
        className="w-300 absolute h-300 opacity-10 mb-6 block z-0 hidden md:hidden lg:block"
      />
      <div className="p-5 lg:z-10 lg:absolute lg:bottom-5 lg:left-5">
        <img
          src={logo}
          alt="Welcome"
          className="lg:w-100 lg:h-100 lg:mb-6 lg:block hidden"
        />
        <h1 className="text-white text-3xl font-bold mb-3">
          Bem-vindo ao Portal do Locador
        </h1>
        <p className="text-white text-lg max-w-[700px]">
          A plataforma SL Online foi criada com o objetivo de revolucionar a
          gestão de locadores de sinuca. Com nossa tecnologia de ponta e
          soluções inovadoras, ajudamos nossos clientes a simplificar e
          automatizar seus processos, aumentar a eficiência e maximizar suas receitas.
          Obrigado por se juntar a nós. Agora vamos evoluir seu negócio!
        </p>
      </div>
    </div>
  );
};

export default WelcomeBanner;
