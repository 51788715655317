import logo from '../assets/logo.png';

import { useUsuario } from '../context/usuario';

import profile from '../assets/profile.webp';

export function Header() {
    const { user, logout } = useUsuario();
    return (
        <header className="w-full py-4 flex items-center justify-between bg-neutral-800 border-b border-gray-600">
            <div className='lg:flex lg:items-center lg:block'>
                <img src={logo} className="max-w-[130px] ml-4 " />
            </div>
            <strong className='text-2sm lg:text-2xl lg:mr-1 font-bold hidden lg:block'>Portal do Locador</strong>
            <div className='flex items-center mr-2'>
                <img
                    className="h-10 w-10 rounded-full border-2 border-blue-500 mr-2"
                    src={profile}
                    alt="foto" />
                <div className='flex flex-col items-start'>
                    <strong className='text-xs lg:text-lg'>Olá,<br/> <strong className='text-blue-500'>{user?.user?.name}</strong></strong>
                    <a href='#' className='absolute top-6 right-3 text-xs lg:text-md lg:top-4 lg:right-4 ' onClick={() => logout()}>Sair</a>
                </div>
            </div>
        </header>
    )
}