import { BrowserRouter } from 'react-router-dom';

import { UsuarioProvider } from './context/usuario';


import Router from './routes';


function App() {
  
  
 
  return (

    <BrowserRouter>
      <UsuarioProvider>
        <Router />
      </UsuarioProvider>
    </BrowserRouter>
  );
}

export default App;
