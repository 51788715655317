import React, { useEffect } from 'react';
import { Lesson } from "./Lesson";

import { useUsuario } from '../context/usuario';


export function Sidebar() {
    const { getSidebar, sidebar } = useUsuario();

    useEffect(() => {
        getSidebar();
    }, []);

    return (
        <aside className="lg:w-[348px] max-h-screen bg-neutral-800 p-6 border-l border-gray-600 lg:block overflow-auto ">
            <span className="font-bold text-2xl pb-6 mb-6 border-b gorder-gray-500 block">
                Cronograma de Aulas
            </span>


            {
                sidebar?.map(item => (<>
                    <span key={`a-${item.id}`} className="font-bold text-2sm pb-6 pt-3 block">
                        {item?.name}
                    </span>
                    <div key={`b-${item.id}`} className="flex flex-col gap-8">
                        {item.lesson.map(lesson => (
                            <Lesson
                                key={lesson?.id}
                                title={lesson?.title}
                                slug={lesson?.id}
                            />
                        ))}

                    </div>
                </>))
            }




        </aside>
    )
}