import React, { useState } from 'react';
import { DefaultUi, Player, Youtube, } from "@vime/react";
import { CaretRight, Asterisk, FileArrowDown, FileImage, Lightning } from "phosphor-react";
import Welcome from './Welcome';
import { useUsuario } from '../context/usuario';

import '@vime/core/themes/default.css';

import logo from '../assets/logo.png';

export function Video({ lesson }) {
    const { loading } = useUsuario();

    if (!lesson.id || loading) {
        return (
            <Welcome />
        )
    }

    return (
        <div className="flex-1 bg-neutral-900  lg:max-h-screen  lg:overflow-auto">
            <div className="bg-black flex justify-center">
                <div className="h-full w-full max-w-[1100px] max-h-[60vh] aspect-video">

                    {lesson?.player}

                </div>
            </div>

            <div className="p-8 max-w-[1100px] mx-auto">
                <div className="flex items-start gap-16 ">
                    <div className="flex-1">
                        <h1 className="text-2xl font-bold">
                            {lesson?.title}
                        </h1>
                        <p className="mt-4 text-gray-400 leading-relaxed">
                            {lesson?.description}
                        </p>

                        <div className="flex items-center gap-4 mt-6">
                            <img
                                className="h-16 w-16 rounded-full border-2 border-blue-500"
                                src={lesson?.teacher.avatar_url}
                                alt="foto" />

                            <div className="leading-relaxed">
                                <strong className="font-bold text-2xl block">
                                    {lesson?.teacher.name}
                                </strong>
                                <span className="text-gray-200 text-sm block">
                                    Com sua expertise em tecnologia e inovação,
                                    ela lidera a equipe da SL Online para fornecer soluções inovadoras para os locadores.
                                    Jessica é conhecida por sua paixão pelo empreendedorismo e sua dedicação ao desenvolvimento
                                    de tecnologias que ajudam a simplificar e automatizar processos empresariais.
                                    {/*lesson?.teacher.description*/}
                                </span>
                            </div>
                        </div>
                    </div>

                   
                </div>
                <div className="flex md:flex-col lg:flex-row gap-4 sm:flex-row min-w[100%] mt-6">
                        <a href={lesson?.community_url} className="p-4 text-sm bg-blue-900 flex items-center rounded font-bold uppercase gap-2 justify-center hover:bg-blue-700 transition-colors">
                            <Asterisk size={24} />
                            Comunidade
                        </a>

                        <a href="https://admin.slonline.com.br" target="_blank" className="p-4 text-sm border border-blue-500 text-blue-500 flex items-center rounded font-bold uppercase gap-2 justify-center hover:bg-blue-500 hover:text-gray-900 transition-colors" rel="noreferrer">
                            <Lightning size={24} />
                            Acesse o SL Online
                        </a>
                    </div>
{/* 
                <div className="gap-8 mt-20 grid grid-cols-2">
                    <a href={lesson?.material_url} className="bg-gray-700 rounded overflow-hidden flex items-stretch gap-6 hover:bg-gray-600 transition-colors">
                        <div className="bg-blue-700 h-full p-6 flex items-center">
                            <FileArrowDown size={40} />
                        </div>
                        <div className="py-6 leading-relaxed">
                            <strong className="text-2xl">Material Complementar</strong>
                            <p className="text-sm text-gray-200 mt-2">
                                Acesse o material complementar para acelerar o seu desenvolvimento
                            </p>
                        </div>
                        <div className="h-full p-6 flex items-center">
                            <CaretRight size={24} />
                        </div>
                    </a>

                    <a href={lesson?.more_url} className="bg-gray-700 rounded overflow-hidden flex items-stretch gap-6 hover:bg-gray-600 transition-colors">
                        <div className="bg-blue-700 h-full p-6 flex items-center">
                            <FileImage size={40} />
                        </div>
                        <div className="py-6 leading-relaxed">
                            <strong className="text-2xl">Mais</strong>
                            <p className="text-sm text-gray-200 mt-2">
                                Mais informações sobre a aula aqui
                            </p>
                        </div>
                        <div className="h-full p-6 flex items-center">
                            <CaretRight size={24} />
                        </div>
                    </a>
                </div>
    */}
            </div>
        </div>
    )
}